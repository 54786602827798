import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import Layout from '../../../../layouts/Site';
import { NextCard } from '../../../../components/Cards';
import CourseCard from '../../../../components/Courses/Card';
import api from '../../../../services/api';

export default function CoursePage(props) {

    const [course, setCourse] = useState({});
    const [value, setValue] = useState(0);

    useEffect(() => {
        window.scrollTo(0, 0);
        api.get(`courses/${props.match.params.id}`).then(res => {
            setCourse(res.data.course);
            setValue(res.data.course.value);
        });
    }, []);

    function replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
        <Layout>
            <Container>

                <section id="course-page" className="section">
                    <Row className="course-page">
                        <Col lg="3" className="sidebar-wrapper">
                            <div className="course-page-card sidebar">
                                <div className="wrapper">
                                    <CourseCard 
                                        color="#14763a" 
                                        image={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + course.thumbPath}
                                    />
                                    <h1 className="name">{capitalizeFirstLetter(replaceAll(props.match.params.name, '-', ' '))}</h1>

                                    <div className="course-page-valor">
                                        <p>Por apenas</p>
                                        <h1>R${value.toLocaleString('pt-br', {minimumFractionDigits: 2})}</h1>
                                    </div>

                                    <Link to={`/finalizar-compra/${props.match.params.id}/${props.match.params.name}`} className="chamada-button w-100 mt-3">
                                        Comprar
                                    </Link>
                                </div>
                                
                            </div>
                        </Col>
                        <Col lg="9">
                            <div className="course-page-card">
                                <div className="wrapper">
                                    <div className="videoWrapper">
                                        <iframe width="560" height="315" src={course.videoLink} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                    </div>

                                    <div className="mt-3 content">
                                        <p dangerouslySetInnerHTML={{__html: course.text}}></p>

                                        <div className="chamada">
                                            <h1>Não perca mais tempo!</h1>
                                            <p>O seu futuro não pode esperar!</p>

                                            <Link to={`/finalizar-compra/${props.match.params.id}/${props.match.params.name}`} className="chamada-button">
                                                Compre agora mesmo!
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </Layout>
    );
}