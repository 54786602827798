import React, { useEffect, useState } from "react";
import jwtDecode from 'jwt-decode';
import { useSelector } from 'react-redux';

export default function Comment(props) {

	const token = useSelector(state => state.auth.token);

	const [user, setUser] = useState({});

	useEffect(() => {
		setUser(jwtDecode(token).user);
	},[])

	function destroy(){
		props.destroy(props.comment.id);
	}

	return (
		<div className="comment">
			{
				!props.professor &&
				<>
				<div className="d-flex justify-content-between">
					<div className="data">
						{props.comment.created_at}
					</div>
					{
						(user.id == props.comment.user.id || user.type == 'admin') &&
						<div className="data">
							<button className="text-danger btn p-0 m-0" onClick={destroy}>Deletar</button>
						</div>
					}
				</div>
				<div className="name">
					{props.comment.user.name}
				</div>
				</>
			}
			<div className="content">
				{
					props.professor ?
						<p dangerouslySetInnerHTML={{__html: props.content}}></p>
					:
						<p dangerouslySetInnerHTML={{__html: props.comment.content}}></p>
				}
			</div>
		</div>
	);
}