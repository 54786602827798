import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

export default function Annotation(props) {

  useEffect(() => {
  }, []);

  return (
    <>
        <div className="comment">
          <div className="d-flex justify-content-between">
            <div className="data">
                {props.annotation.created_at}
            </div>
            <div className="data">
              <button className="text-danger btn p-0 m-0" onClick={() => props.destroy(props.annotation.id)}>Deletar</button>
            </div>
          </div>

            <div className="content">
              <p dangerouslySetInnerHTML={{__html: props.annotation.content}}></p>
            </div>
        </div>
    </>
  );
}