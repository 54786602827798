import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Table, Container, CustomInput, Collapse, Button, CardBody, Card  } from 'reactstrap';

import AdminLayout from '../../../layouts/Admin';
import Disciplines from '../../../components/AdminFilters/Disciplines';
import Juries from '../../../components/AdminFilters/Juries';
import Formation from '../../../components/AdminFilters/Formation';
import Institutes from '../../../components/AdminFilters/Institutes';
import Jobs from '../../../components/AdminFilters/Jobs';
import Acts from '../../../components/AdminFilters/Acts';
import Tags from '../../../components/AdminFilters/Tags';

export default function View() {

    useEffect(() => {
    }, []);

    return (
        <AdminLayout
            title="Filtros"
        >
            
            <Disciplines/>
            <Juries/>
            <Formation/>
            <Institutes/>
            <Acts/>
            <Jobs/>
            <Tags/>
            

        </AdminLayout>
    );
}