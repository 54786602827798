import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, FormFeedback  } from 'reactstrap';
import ProfileLayout from '../../../../layouts/Profile';
import Wrapper from '../../../..//components/Profile/Wrapper';
import api from '../../../../services/api';
import cepPromise from 'cep-promise'
import InputMask from 'react-input-mask';

export default function View() {

  const [user, setUser] = useState({});
  const [saving, setSaving] = useState(false);

  const [password, setPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [actualPassword, setActualPassword] = useState();

  const [diffPassword, setDiffPassword] = useState();
  const [invalidPassword, setInvalidPassword] = useState(false);

  const [success, setSuccess] = useState(false);

  useEffect(() => {
  }, []);

  function submit(e){
    e.preventDefault();

    if(password != newPassword){
      setDiffPassword(true);
      return setTimeout(function(){ setDiffPassword(false) }, 6000);
    }
    
    setSaving(true)
    api.post('users/change/password', {
      actualPassword,
      newPassword,
    }).then(res => {
      if(res.data.message == 'Invalid password'){
        setInvalidPassword(true);
        setTimeout(function(){ setInvalidPassword(false) }, 6000);
        return setSaving(false);
      }
      setSaving(false);
      setPassword('');
      setNewPassword('');
      setActualPassword('');
      setSuccess(true);
      setTimeout(function(){ setSuccess(false) }, 3000);
    });
  }

  return (
    <ProfileLayout
      name={user.name}
    >
      <Form onSubmit={submit}>
        <Wrapper title="Alterar senha"
          extra={
            <button type="submit" className="site-button site-button-sm" disabled={saving}>{saving ? 'Salvando...' : 'Salvar alterações'}</button>
          }
        >
          <Container fluid>
            <Row>
              <Col lg="4" className="pl-lg-0 pr-lg-1">
                <FormGroup>
                    <Label for="newpass">Senha atual</Label>
                    <Input type="password" id="newpass" autoComplete="off" value={actualPassword} onChange={(e) => setActualPassword(e.target.value)} invalid={invalidPassword} required/>
                    <FormFeedback>Senha inválida!</FormFeedback>
                </FormGroup>
              </Col>
              <Col lg="4" className="pl-lg-0 pr-lg-1">
                <FormGroup>
                    <Label for="newpass">Nova senha</Label>
                    <Input type="password" id="newpass" autoComplete="off" value={password} onChange={(e) => setPassword(e.target.value)} invalid={diffPassword} required/>
                </FormGroup>
              </Col>
              <Col lg="4" className="pl-lg-0 pl-lg-1">
                <FormGroup>
                    <Label for="oldpass">Confirme sua senha</Label>
                    <Input type="password" id="oldpass" autoComplete="off" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} invalid={diffPassword} required/>
                    <FormFeedback>As senhas não são iguais!</FormFeedback>
                </FormGroup>
              </Col>
            </Row>
          </Container>
          {
            success &&
            <p className="text-success">Senha alterada com sucesso!</p>
          }
        </Wrapper>
      </Form>
    </ProfileLayout>
  );
}