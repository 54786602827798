import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import Logo from '../../assets/img/logo.svg';

function NextCard(props) {

  useEffect(() => {
  }, []);

  return (
    <>
        <div className="card">
            <div className="content">
                <h1 className="title">{props.edital}</h1>

                <div className="vagas">
                    <h1>{props.vagas}</h1>
                    <span>Vagas</span>
                </div>

                <div className="salario">
                    <span>
                        Salários até
                    </span>
                    <h1>R${props.salario.toLocaleString('pt-br', {minimumFractionDigits: 2})}</h1>
                </div>
            </div>

            <div className="inscricoes">
                Inscrições até {props.inscricao}
            </div>
        </div>
    </>
  );
}

function Card(props) {

    return (
        <>
            <div className={props.contentCard ? 'card cardNotHover notCentered contentCard' : 'card'}>
                <div className="content">
                    {
                        props.title &&
                        <h1 className="title">{props.title}</h1>
                    }

                    <div className="vagas">
                        <h1>{props.big}</h1>
                    </div>

                    {props.children}
                </div>

                {
                    props.footer &&
                    <div className="inscricoes">
                        {props.footer}
                    </div>
                }
            </div>
        </>
    )
}

function PlanCard(props) {

    return (
      <>
          <div className={props.free ? 'card planCard planFree' : 'card planCard'}>
                <div className="content">
                    <h1 className={props.free ? 'titleFree' : 'title'}>{props.name}</h1>
    
                    <div className="benefits">
                        <h1>
                            Benefícios
                        </h1>
                        <ul>
                            <li>Beneficio</li>
                            <li>Beneficio</li>
                            <li>Beneficio</li>
                            <li>Beneficio</li>
                            <li>Beneficio</li>
                            <li>Beneficio</li>
                            <li>Beneficio</li>
                        </ul>
                    </div>
                </div>

                {
                    !props.free &&
                    <div className="priceGroup">
                        <div className="d-flex align-items-center">
                            <span className="times">R$<br/>{props.yearly && '12x'}</span><h1>{props.value.toLocaleString('pt-br', {minimumFractionDigits: 2})}</h1>
                        </div>
                        <div className="vista">{props.description}</div>
                    </div>
                }
    
                <div className="inscricoes">
                    {
                        props.disabled ?
                        <button disabled className="site-button">{props.buttonText}</button>
                        :
                        (
                            props.free ?
                            <Link to={`/questoes`} className="site-button">{props.buttonText}</Link>
                            :
                            <Link to={`/assinatura/${props.slug}`} className="site-button">{props.buttonText}</Link>
                        )
                    }
                </div>
          </div>
      </>
    );
}

export { NextCard, Card, PlanCard };