import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Table, Form, FormGroup, Input, Label, Modal, ModalHeader, ModalBody, ModalFooter  } from 'reactstrap';

import ProfileLayout from '../../../../layouts/Profile';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import Wrapper from '../../../../components/Profile/Wrapper';
import api from '../../../../services/api';

export default function View() {

  const [notes, setNotes] = useState([]);

  const [isOpen, setOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const [name, setName] = useState();

  function toggleDialog(){
      setConfirmationDialog(!confirmationDialog);
  }

  function callbackDialog(){
    api.delete(`notebooks/${confirmationDialog}`);
    setNotes(notes.filter(note => note.id != confirmationDialog));
    toggleDialog();
  }

  function toggle(){
    setOpen(!isOpen);
  }

  function submit(e){
    e.preventDefault();
    api.post(`notebooks`, {
      name
    }).then(res => {
      setNotes([...notes, res.data.note]);
      setName("");
      toggle();
    });
  }

  useEffect(() => {
    api.get('notebooks').then(res => {
      setNotes(res.data.notes);
    });
  }, []);

  return (
    <ProfileLayout>
        <Wrapper title="Meus cadernos"
          extra={
            <button type="submit" className="site-button site-button-sm" onClick={toggle}>Novo caderno</button>
          }
        >
          <Table responsive>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Qtd. questões</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                  {
                    notes.map(note => (
                      <tr>
                          <td>{note.name}</td>
                          <td className="text-center">{note.qtd}</td>
                          <td className="text-right">
                              <Link to={`/cadernos/${note.id}`} className="site-button site-button-sm site-button">Visualizar</Link>{' '}
                              <button className="site-button site-button-danger site-button-sm" onClick={() => setConfirmationDialog(note.id)}>Deletar</button>
                          </td>
                      </tr>
                    ))
                  }
                </tbody>
            </Table>
        </Wrapper>

        
      <Modal isOpen={isOpen} toggle={toggle}>
        <Form onSubmit={submit}>
          <ModalHeader toggle={toggle}>Novo caderno</ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label for="name">Nome do caderno</Label>
              <Input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
              <button className="site-button site-button-sm" type="submit">Cadastrar</button>{' '}
              <button className="site-button site-button-sm site-button-secondary" onClick={toggle}>Cancelar</button>
          </ModalFooter>
        </Form>
      </Modal>

      <ConfirmationDialog
          isOpen={confirmationDialog}
          toggle={toggleDialog}
          callback={callbackDialog}
      >
          Você tem certeza que deseja deletar esse caderno?
      </ConfirmationDialog>
    </ProfileLayout>
  );
}