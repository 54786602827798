import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Table  } from 'reactstrap';

import FilterItem from '../FilterItem';
import api from '../../../services/api';
import ConfirmationDialog from '../../../components/ConfirmationDialog';

export default function Component(props) {

    const [items, setItems] = useState([]);

    useEffect(() => {
        api.get('juries').then(res => {
            setItems(res.data.juries);
        });
    }, []);

    const [confirmationDialog, setConfirmationDialog] = useState(false);

    function toggleDialog(){
        setConfirmationDialog(!confirmationDialog);
    }

    function callbackDialog(){
        api.delete(`juries/${confirmationDialog}`);
        setItems(items.filter(item => item.id != confirmationDialog));
        toggleDialog();
    }

    const [name, setName] = useState();

    function submit(e){
        e.preventDefault();

        api.post('juries', {
            name,
        }).then(res => {
            setItems([...items, res.data.jury]);
            setName("")
        });
    }

    return (
        <>
            <FilterItem name="Bancas">
                <Form onSubmit={submit}>
                    <Row className="align-items-end">
                        <Col>
                            <FormGroup>
                                <Label>Nome</Label>
                                <Input type="text" id="name" required size="sm" value={name} onChange={(e) => setName(e.target.value)}/>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <button type="submit" className="site-button site-button-sm mt-2">Adicionar</button>
                            </FormGroup>
                        </Col>
                    </Row>
                </Form>
                <Table responsive>
                    <tbody>
                        {
                            items.map((item, index) => (
                                <tr>
                                    <td>{ item.name }</td>
                                    <td className="text-right">
                                        <button className="site-button site-button-danger site-button-sm" onClick={() => setConfirmationDialog(item.id)}>Remover</button>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </FilterItem>

            
            <ConfirmationDialog
                isOpen={confirmationDialog}
                toggle={toggleDialog}
                callback={callbackDialog}
            >
                Você tem certeza que deseja remover esse filtro?
            </ConfirmationDialog>
        </>
    );
}