import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";
import { Table } from 'reactstrap';
import AdminLayout from '../../../../layouts/Admin';
import api from '../../../../services/api';

export default function View(props) {

  const [users, setUsers] = useState([]);
  const [courseName, setCourseName] = useState("");

  useEffect(() => {
    api.get(`courses/${props.match.params.id}/users`).then(res => {
      setUsers(res.data.users);
    });
    api.get(`courses/${props.match.params.id}`).then(res => {
      setCourseName(res.data.course.name);
    })
  }, []);

  return (
    <AdminLayout
        title={`${courseName} - Alunos`}
    >
        <Table responsive>
            <thead>
              <tr>
                <th className="text-center">#</th>
                <th>Nome</th>
                <th>Email</th>
                <th>Membro desde</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                users.map((user, index) => (
                  <tr>
                      <td className="text-center">{index + 1}</td>
                      <td>{user.name}</td>
                      <td>
                        {user.email}
                      </td>
                      <td>
                        {user.created_at}
                      </td>
                      <td>
                        <Link to={`/admin/cursos/alunos/${user.id}`} className="site-button site-button-sm">Cursos do aluno</Link>
                      </td>
                  </tr>
                ))
              }
            </tbody>
        </Table>
    </AdminLayout>
  );
}