import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import Layout from '../../../layouts/Site';

import RefusedSVG from '../../../assets/img/checkout/refused.svg';

export default function CheckoutRefused(props) {

    return (
    <Layout>
        <div className="text-center my-5">
            <img src={RefusedSVG} height="200px"/>
            <h1><strong>Compra rejeitada!</strong></h1>
            <p>Verifique os seus dados de pagamento e se seu cartão está em ordem.</p>
            <Link to="/cursos-online" className="site-button site-button">Continuar comprando</Link>
        </div> 
    </Layout>
  );
}