import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";
import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';
import ClassLayout from '../../../../layouts/Class';
import Wrapper from '../../../../components/Profile/Wrapper';
import Thumb from '../../../../components/Courses/Thumb';
import api from '../../../../services/api';
import { useSelector } from 'react-redux';

export default function View(props) {

  const token = useSelector(state => state.auth.token);

  const [courseClass, setCourseClass] = useState([]);
  const [files, setFiles] = useState([]);
  const [nextTree, setNextTree] = useState([]);

  useEffect(() => {
    api.get(`classes/${props.match.params.aula}`).then(res => {
      setCourseClass(res.data.courseClass);
      setFiles(res.data.files);
      setNextTree(res.data.nextTree);
    })
  }, [props.match.params.aula]);

  return (
    <ClassLayout 
      courseClass={courseClass}
      files={files}
      setCourseClass={setCourseClass}
      nextTree={nextTree}
      courseId={props.match.params.id}
    />
  );
}