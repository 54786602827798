import React, { useEffect, useState } from "react";
import {
  Link,
  useLocation
} from "react-router-dom";

import { Row, Col, Container, CustomInput } from 'reactstrap';
import { Content } from '../../components/Cards';
import Wrapper from '../../components/Profile/Wrapper';

import Logo from '../../assets/img/logo.svg'

export default function AdminLayout(props) {

    const location = useLocation();

    useEffect(() => {
    }, []);

    return (
        <Container>
            <div className="admin-nav">
                <ul>
                    <Link to="/"><li>Voltar ao site</li></Link>
                    <Link to="/"><li>Sair</li></Link>
                </ul>
            </div>
            <Row className="profile">
                <Col lg="3">
                    <div className="profile-card">
                        <div className="p-3 text-center">
                            <Link to='/admin'>
                                <img src={Logo} height="70px"/>
                            </Link>
                        </div>
                        <ul className="sidebarul">
                            <Link to="/admin/proximos-concursos"><li className={location.pathname == '/admin/proximos-concursos' ? 'selected' : ''}>Próximos concursos</li></Link>
                            <Link to="/admin/cursos"><li className={location.pathname == '/admin/cursos' ? 'selected' : ''}>Cursos</li></Link>
                            <Link to="/admin/questoes"><li className={location.pathname == '/admin/questoes' ? 'selected' : ''}>Questões</li></Link>
                            <Link to="/admin/filtros"><li className={location.pathname == '/admin/filtros' ? 'selected' : ''}>Filtros</li></Link>
                            <Link to="/admin/problemas-relatados"><li className={location.pathname == '/admin/problemas-relatados' ? 'selected' : ''}>Problemas relatados</li></Link>
                            <Link to="/admin/assinantes"><li className={location.pathname == '/admin/assinantes' ? 'selected' : ''}>Assinatura</li></Link>
                            <Link to="/admin/usuarios"><li className={location.pathname == '/admin/usuarios' ? 'selected' : ''}>Usuários</li></Link>
                        </ul>
                    </div>
                </Col>
                <Col lg="9">
                    <Wrapper 
                        title={props.title}
                        extra={
                            props.extra
                        }
                    >
                        {props.children}
                    </Wrapper>
                </Col>
            </Row>
        </Container>
  );
}