import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";
import { Form, FormGroup, Input, Label, Row, Col, Container, FormFeedback  } from 'reactstrap';
import Layout from '../../layouts/Site';
import { Card } from '../../components/Cards';
import api from '../../services/api';
import { loginAction } from '../../store/modules/auth/actions';
import { useSelector, useDispatch } from 'react-redux';

export default function Login(props) {

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState();
    const [invalidEmail, setInvalidEmail] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
    }, []);

    function login(e){
        e.preventDefault();
        setLoading(true);

        api.post('users/reset/password', {
            email
        }).then(res => {
            if(res.data.message == 'Invalid email'){
                setInvalidEmail(true);
                setTimeout(function(){ setInvalidEmail(false) }, 6000);

                return setLoading(false);
            }


            setSuccess(true)
            setEmail("");
            setLoading(false);
        })
    }

    return (
        <Layout>
            <Container>
                <section id="login" className="section ml-auto mr-auto" style={{maxWidth: '900px'}}>
                    <Row className="justify-content-center">
                        <Col lg="6">
                            <Card
                                title="Recuperar senha"
                                contentCard
                            >
                                {
                                    success ?
                                    <div>
                                        <p>Um email com uma nova senha foi enviada para o endereço de email informado.</p>
                                        <Link to="/entrar" className="text-primary">Fazer login</Link>
                                    </div>
                                    :
                                    <Form className="text-left" onSubmit={login}>
                                        <FormGroup>
                                            <Label for="email">E-mail</Label>
                                            <Input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} invalid={invalidEmail}/>
                                            <FormFeedback>E-mail inválido!</FormFeedback>
                                        </FormGroup>
                                        <FormGroup>
                                            <button type="submit" className="site-button" disabled={loading}>{ loading ? 'Verificando...' : 'Recuperar' }</button>
                                        </FormGroup>
                                    </Form>
                                }
                            </Card>
                        </Col>
                    </Row>
                </section>
            </Container>
        </Layout>
    );
}