import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import api from './services/api';
import { loginAction, logoutAction } from './store/modules/auth/actions';

import Loader from './components/Loader';

import Login from "./views/Login";
import RecouverPassword from "./views/RecouverPassword";

import Questions from "./views/Site/Questions";
import MyQuestions from "./views/Site/MyQuestions";
import Notebook from "./views/Site/Notebook";
import SignaturePlans from "./views/Site/SignaturePlans";
import CoursePage from "./views/Site/Course/CoursePage";
import CoursesPage from "./views/Site/Course/CoursesPage";
import UserDashboard from "./views/Site/Profile/UserDashboard";
import UserData from "./views/Site/Profile/UserData";
import UserNotebooks from "./views/Site/Profile/UserNotebooks";
import UserQuestions from "./views/Site/Profile/UserQuestions";
import UserSignature from "./views/Site/Profile/UserSignature";
import UserCourses from "./views/Site/Profile/UserCourses";
import ChangePassword from "./views/Site/Profile/ChangePassword";

import Checkout from "./views/Site/Checkout";
import SignCheckout from "./views/Site/SignCheckout";
import CheckoutRefused from "./views/Site/CheckoutRefused";

import Course from "./views/Site/Course/Course";
import Class from "./views/Site/Course/Class";

import AdminQuestions from "./views/Admin/Questions";
import AdminAddQuestion from "./views/Admin/AddQuestion";
import AdminQuestion from "./views/Admin/Question";

import Nexts from "./views/Admin/Nexts";
import AddNext from "./views/Admin/Nexts/Add";
import UpdateNext from "./views/Admin/Nexts/Update";

import Subscribers from "./views/Admin/Subscribers";
import Users from "./views/Admin/Users";

import Courses from "./views/Admin/Courses";
import AddCourses from "./views/Admin/Courses/Add";
import UpdateCourse from "./views/Admin/Courses/Update";
import CourseClasses from "./views/Admin/Courses/Classes";
import CourseUpdateClass from "./views/Admin/Courses/Classes/Update";
import CourseAddClass from "./views/Admin/Courses/Classes/Add";
import CourseStudents from "./views/Admin/Courses/Students";
import StudentCourses from "./views/Admin/Courses/Students/Courses";

import Filters from "./views/Admin/Filters";

import Problems from "./views/Admin/Problems";

import Home from './views/Site/Home';
import Approveds from './views/Site/Approveds';

import jwtDecode from 'jwt-decode';

const AdminRoute = ({ component: Component, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);

  const [user, setUser] = useState({});

  useEffect(() => {
    if(token){
      api.get(`auth/token/${token}`).then(res => {
        if(jwtDecode(token).user.type == 'admin'){
          dispatch(loginAction(res.data.access_token));
          setAuthenticated(true);
          setLoading(false);
        } else {
          setAuthenticated(false);
          setLoading(false);
          dispatch(logoutAction());
        }
      }).catch(err => {
        setLoading(false);
        dispatch(logoutAction());
        setAuthenticated(false);
      });
    } else {
      setLoading(false);
      dispatch(logoutAction());
      setAuthenticated(false);
    }

    api.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        dispatch(logoutAction());
        return setAuthenticated(false);
      }
    });
  }, [])

  return (
    loading ?
    <Loader loading={loading}/>
    :
    <Route
      {...rest}
      render={
        props => authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect 
            to={{
              pathname: "/cadastre-se",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

const PrivateRoute = ({ component: Component, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);

  useEffect(() => {
    if(token){
      api.get(`auth/refresh`).then(res => {
        dispatch(loginAction(res.data.access_token));
        setAuthenticated(true);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
        dispatch(logoutAction());
        setAuthenticated(false);
      });
    } else {
      setLoading(false);
      dispatch(logoutAction());
      setAuthenticated(false);
    }

    api.interceptors.response.use(response => {
      return response;
    }, error => {
      if (error.response.status === 401) {
        dispatch(logoutAction());
        return setAuthenticated(false);
      }
    });
  }, [])

  return (
    loading ?
    <Loader loading={loading}/>
    :
    <Route
      {...rest}
      render={
        props => authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect 
            to={{
              pathname: "/cadastre-se",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  )
}

const PublicRoute = ({ component: Component, ...rest }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();

  const token = useSelector(state => state.auth.token);

  useEffect(() => {
    if(token){
      api.get(`auth/refresh`).then(res => {
        dispatch(loginAction(res.data.access_token));
        setAuthenticated(true);
        setLoading(false);
      }).catch(err => {
        dispatch(logoutAction());
        setAuthenticated(false);
        setLoading(false);
      });
    } else {
      setAuthenticated(false);
      setLoading(false);
    }
  }, [])

  return (
    loading ?
    <Loader loading={loading}/>
    :
    <Route
      {...rest}
      render={
        props => authenticated ? (
          <Redirect 
            to={{
              pathname: "/perfil",
              state: { from: props.location }
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export default function Routes() {
  return (
    <Router>
        <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/aprovados" exact component={Approveds}/>
            <PrivateRoute path="/questoes" exact component={Questions}/>
            <PrivateRoute path="/questoes/:questions" exact component={MyQuestions}/>
            <PrivateRoute path="/cadernos/:note" exact component={Notebook}/>

            <Route path="/cursos-online" exact component={CoursesPage}/>
            <Route path="/cursos/:id/:name" exact component={CoursePage}/>

            <Route path="/assinatura" exact component={SignaturePlans}/>
            <PrivateRoute path="/assinatura/:plan" exact component={SignCheckout}/>

            <PrivateRoute path="/perfil" exact component={UserDashboard}/>
            <PrivateRoute path="/perfil/meus-dados" exact component={UserData}/>
            <PrivateRoute path="/perfil/meus-cadernos" exact component={UserNotebooks}/>
            <PrivateRoute path="/perfil/minhas-questoes" exact component={UserQuestions}/>
            <PrivateRoute path="/perfil/assinatura" exact component={UserSignature}/>
            <PrivateRoute path="/perfil/meus-cursos" exact component={UserCourses}/>
            <PrivateRoute path="/perfil/trocar-senha" exact component={ChangePassword}/>

            <PrivateRoute path="/perfil/cursos/:id" exact component={Course}/>
            <PrivateRoute path="/perfil/cursos/:id/:aula" exact component={Class}/>
            
            <PrivateRoute path="/finalizar-compra/:id/:name" exact component={Checkout}/>
            <PrivateRoute path="/compra-recusada" exact component={CheckoutRefused}/>

            <PublicRoute path="/entrar" component={Login}/>
            <PublicRoute path="/cadastre-se" component={Login}/>
            <PublicRoute path="/recuperar-senha" component={RecouverPassword}/>

            <AdminRoute exact path="/admin/proximos-concursos" component={Nexts}/>
            <AdminRoute exact path="/admin/proximos-concursos/cadastrar" component={AddNext}/>
            <AdminRoute exact path="/admin/proximos-concursos/editar/:id" component={UpdateNext}/>

            <AdminRoute admin exact path="/admin/cursos" component={Courses}/>
            <AdminRoute admin exact path="/admin/cursos/:id/alunos" component={CourseStudents}/>
            <AdminRoute admin exact path="/admin/cursos/alunos/:id" component={StudentCourses}/>
            <AdminRoute admin exact path="/admin/cursos/cadastrar" component={AddCourses}/>
            <AdminRoute admin exact path="/admin/cursos/:id" component={UpdateCourse}/>
            <AdminRoute admin exact path="/admin/cursos/:id/aulas" component={CourseClasses}/>
            <AdminRoute admin exact path="/admin/cursos/:id/aulas/cadastrar" component={CourseAddClass}/>
            <AdminRoute admin exact path="/admin/cursos/:id/aulas/:aula" component={CourseUpdateClass}/>

            <AdminRoute admin exact path="/admin/filtros" component={Filters}/>

            <AdminRoute admin exact path="/admin/problemas-relatados" component={Problems}/>

            <AdminRoute admin exact path="/admin/questoes" component={AdminQuestions}/>
            <AdminRoute admin exact path="/admin/questoes/cadastrar" component={AdminAddQuestion}/>
            <AdminRoute admin exact path="/admin/questoes/:id" component={AdminQuestion}/>

            <AdminRoute admin exact path="/admin/assinantes" component={Subscribers}/>
            <AdminRoute admin exact path="/admin/usuarios" component={Users}/>

            <Redirect from="/admin" to="/admin/cursos" />
        </Switch>
    </Router>
  );
}