import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { FormGroup, Row, Col, CustomInput, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import api from '../../../services/api';
import AdminLayout from '../../../layouts/Admin';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import ClipLoader from "react-spinners/ClipLoader";

export default function Questions() {
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    api.get('questions')
      .then(res => {
        const { questions } = res.data;

        setQuestions(questions.data);
      })
  }, []);

  function deleteQuestion(id){
    api.delete(`questions/${id}`)
      .then(res => {
        setQuestions(questions.filter(question => question.id != id));
      })
  }

  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [confirmationCache, setConfirmationCache] = useState();

  function toggleDialog(){
      setConfirmationDialog(!confirmationDialog);
  }

  function openDialog(id){
      setConfirmationCache(id);
      setConfirmationDialog(true);
  }

  function callbackDialog(){
    deleteQuestion(confirmationCache);
    toggleDialog();
  }

	const [nextPage, setNextPage] = useState(2);
	const [loadingContent, setLoadingContent] = useState(false);

	window.onscroll = function(ev) {
    if(window.location.pathname == '/admin/questoes'){
      if (((window.innerHeight + window.scrollY) >= document.body.offsetHeight - 100 ) && !loadingContent) {
        setLoadingContent(true);
        api.get(`questions?page=${nextPage}`).then(res => {
          setQuestions([...questions, ...res.data.questions.data]);
          setNextPage(res.data.questions.current_page + 1);
          setLoadingContent(false);
        });
      }
    }
	};

  return (
    <>
    <AdminLayout
        title="Questões"
        extra={
            <Link to="questoes/cadastrar" className="site-button site-button-sm">Cadastrar nova questão</Link>
        }
    >
      
      <ConfirmationDialog
            isOpen={confirmationDialog}
            toggle={toggleDialog}
            callback={callbackDialog}
        >
            Você tem certeza que deseja deletar essa questão?
      </ConfirmationDialog>

      {
        questions.map((question) => (
          <div className="questionWrapper">    
          
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                {
                  question.canceled == 1 &&
                  <div className="canceled" style={{marginRight: 5}}>Questão anulada</div>
                }
                {
                  question.outdated == 1 &&
                  <div className="canceled">Questão desatualizada</div>
                }
              </div> 
              <div>
                <Link to={`questoes/${question.id}`} className="site-button site-button-sm">Editar</Link>{' '}
                <button className="site-button site-button-sm site-button-danger" onClick={() => openDialog(question.id)}>Deletar</button>
              </div>
            </div>

            <div>
              {
                question.tags.map(tag => (
                  <span style={{backgroundColor: 'whitesmoke', border: '1px solid #c4c4c4', borderRadius: 2, padding: 5, fontSize: '0.8em', marginRight: 5}}>
                    {tag.text}
                  </span>
                ))
              }
            </div>

            <div className="mt-4">
              <p dangerouslySetInnerHTML={{__html: question.wording}} className="wording-content"></p>
            </div>

            <div className="mt-4 mb-4">
              {
                question.alternatives.map((alternative, index) => (
                  <FormGroup key={index}>
                    <Row>
                      <Col md="1" className="d-flex align-items-center">
                        {
                          !question.modal == 'Certo ou errado' &&
                          <strong className="mr-2">{alternative.letter})</strong>
                        }
                        <CustomInput type="radio" id={`radio${index}${question.id}`} name={`alternative${index}${question.id}`} defaultChecked={alternative.correct} disabled/>
                      </Col>
                      <Col md="11">
                        <div className="ck-content" dangerouslySetInnerHTML={{__html: alternative.content}}></div>
                      </Col>
                    </Row>
                  </FormGroup>
                ))
              }
            </div>

            <hr/>

          </div>
        ))
      }

      <ClipLoader
          size={25}
          color={"#2087F5"}
          loading={loadingContent}
          className="mb-4"
      />

      </AdminLayout>
    </>
  );
}