import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
} from "react-router-dom";
import Gallery from 'react-photo-gallery';

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import Layout from '../../../layouts/Site';

import Header from '../../../components/Header';

import api from '../../../services/api';

import Book from '../../../assets/img/header/book.svg';

import Aprovado1 from '../../../assets/img/aprovados/1.jpg';
import Aprovado2 from '../../../assets/img/aprovados/2.jpg';
import Aprovado3 from '../../../assets/img/aprovados/3.jpg';
import Aprovado4 from '../../../assets/img/aprovados/4.jpg';
import Aprovado5 from '../../../assets/img/aprovados/5.jpg';
import Aprovado6 from '../../../assets/img/aprovados/6.jpg';
import Aprovado7 from '../../../assets/img/aprovados/7.jpg';
import Aprovado8 from '../../../assets/img/aprovados/8.jpg';
import Aprovado9 from '../../../assets/img/aprovados/9.jpg';
import Aprovado10 from '../../../assets/img/aprovados/10.jpg';
import Aprovado11 from '../../../assets/img/aprovados/11.jpg';
import Aprovado12 from '../../../assets/img/aprovados/12.jpg';
import Aprovado13 from '../../../assets/img/aprovados/13.jpg';
import Aprovado14 from '../../../assets/img/aprovados/14.jpg';
import Aprovado15 from '../../../assets/img/aprovados/15.jpg';
import Aprovado16 from '../../../assets/img/aprovados/16.jpg';
import Aprovado17 from '../../../assets/img/aprovados/17.jpg';
import Aprovado18 from '../../../assets/img/aprovados/18.jpg';
import Aprovado19 from '../../../assets/img/aprovados/19.jpg';
import Aprovado20 from '../../../assets/img/aprovados/20.jpg';
import Aprovado21 from '../../../assets/img/aprovados/21.jpg';

export default function Home(props) {

    const [courses, setCourses] = useState([]);
    const [nexts, setNexts] = useState([]);
    const [total, setTotal] = useState(0);
    const [totalVideos, setTotalVideos] = useState(0);

    const photos = [
        {
          src: Aprovado1,
          width: 4,
          height: 3
        },
        {
            src: Aprovado2,
            width: 4,
            height: 3
        },
        {
            src: Aprovado3,
            width: 4,
            height: 3
        },
        {
            src: Aprovado4,
            width: 4,
            height: 3
        },
        {
            src: Aprovado5,
            width: 4,
            height: 3
        },
        {
            src: Aprovado6,
            width: 4,
            height: 3
        },
        {
            src: Aprovado7,
            width: 4,
            height: 3
        },
        {
            src: Aprovado8,
            width: 4,
            height: 3
        },
        {
            src: Aprovado9,
            width: 4,
            height: 3
        },
        {
            src: Aprovado10,
            width: 4,
            height: 3
        },
        {
            src: Aprovado11,
            width: 4,
            height: 3
        },
        {
            src: Aprovado12,
            width: 4,
            height: 3
        },
        {
            src: Aprovado13,
            width: 4,
            height: 3
        },
        {
            src: Aprovado14,
            width: 4,
            height: 3
        },
        {
            src: Aprovado15,
            width: 4,
            height: 3
        },
        {
            src: Aprovado16,
            width: 4,
            height: 3
        },
        {
            src: Aprovado17,
            width: 4,
            height: 3
        },
        {
            src: Aprovado18,
            width: 4,
            height: 3
        },
        {
            src: Aprovado19,
            width: 4,
            height: 3
        },
        {
            src: Aprovado20,
            width: 4,
            height: 3
        },
        {
            src: Aprovado21,
            width: 4,
            height: 3
        },
    ];

    useEffect(() => {
        api.get('courses').then(res => setCourses(res.data.courses));
        api.get('nexts').then(res => setNexts(res.data.nexts));
        api.get('questions/data/total').then(res => setTotal(res.data.total));
        api.get('questions/data/total/videos').then(res => setTotalVideos(res.data.total));
    }, []);

    const [name, setName] = useState('');
    const [tel, setTel] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);

    function sendMail(e){
        e.preventDefault();
        setSending(true);
        api.post('contact', {
            name,
            tel,
            email,
            message
        }).then(res => {
            setSending(false);
            setSent(true);
            setName('');
            setTel('');
            setEmail('');
            setMessage('');
        }).catch(err => {
            setSending(false);
        })
    }

    return (
        <Layout>
            <Container>
                <section id="aprovados" className="section text-center">
                    <h1 style={{fontWeight: 'bold', fontSize: '3em', marginBottom: 50}}>Veja nossos alunos já aprovados</h1>
                    <Gallery photos={photos} />
                </section>

                <section id="contato" className="section">
                    <div className="text-center">
                        <p className="mb-0">Comece seu futuro agora</p>
                        <h1 className="big-title"><strong>Nos mande uma mensagem</strong></h1>
                    </div>
                    <Row className="mt-5">
                        <Col lg={6}>
                            <h1>Via e-mail</h1>
                            <Form onSubmit={sendMail}>
                                <FormGroup>
                                    <Label for="name">Nome</Label>
                                    <Input type="text" id="name" value={name} onChange={e => setName(e.target.value)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="tel">Telefone</Label>
                                    <Input type="text" id="tel" value={tel} onChange={e => setTel(e.target.value)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">E-mail</Label>
                                    <Input type="text" id="email" value={email} onChange={e => setEmail(e.target.value)}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="mensagem">Mensagem</Label>
                                    <Input type="textarea" id="mensagem" value={message} onChange={e => setMessage(e.target.value)}/>
                                </FormGroup>
                                <div class="text-center">
                                    {
                                        sent ?
                                        <p>Contato enviado com sucesso!</p>
                                        :
                                        <button type="submit" className="site-button" disabled={sending}>{sending ? 'ENVIANDO' : 'ENVIAR'}</button>
                                    }
                                </div>
                            </Form>
                        </Col>
                        <Col lg={6}>
                            <div className="mb-5">
                                <h1>Via telefone</h1>
                                <h1><i class="fab fa-whatsapp"></i> (32) 9 9185-1977</h1>
                                <a href="tel:5532991851977" target="_blank" className="site-button site-button-dark">LIGAR AGORA</a>{' '} 
                                <a href="https://api.whatsapp.com/send?phone=5532991851977" target="_blank" className="site-button site-button-success">CHAMAR NO WHATSAPP</a>
                            </div>
                            <div>
                                <h1>Ou venha nos visitar</h1>
                                <p><i class="fas fa-map-marker-alt"></i> Avenida Astolfo Dutra, 792/101, Centro, Cataguases</p>
                            </div>
                        </Col>
                    </Row>
                </section>
            </Container>
        </Layout>
    );
}