import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect,
  useLocation
} from "react-router-dom";
import { Row, Col, Container  } from 'reactstrap';
import Layout from '../Site';
import { logoutAction } from '../../store/modules/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

export default function ProfileLayout(props) {

    const dispatch = useDispatch();
    const location = useLocation();

    const [toLogin, setToLogin] = useState(false);

    const token = useSelector(state => state.auth.token);
    const [user, setUser] = useState({});

    useEffect(() => {
        if(token){
            setUser(jwtDecode(token).user);
        }
    }, []);

    const initials = () => {
        if(user.name){
            let names = user.name.split(' ');
    
            if(names.length >= 2){
                return (names[0][0].toUpperCase()+names[1][0].toUpperCase());
            } else {
                return (names[0][0].toUpperCase()+names[0][1].toUpperCase());
            }
        }
    }

    function logout(){
        setToLogin(true);
        dispatch(logoutAction());
    }

    return (
    <Layout>
        { toLogin && <Redirect to="/entrar"/>}
        <Container>
            <Row className="profile">
                <Col lg="3">
                    <div className="profile-card">
                        <div className="wrapper">
                            <div className="photo">
                                {initials()}
                            </div>
                            <h1 className="name">{user.name}</h1>
                        </div>
                        <ul className="sidebarul">
                            <Link to="/perfil"><li className={location.pathname == '/perfil' ? 'selected' : ''}>Meu desempenho</li></Link>
                            <Link to="/perfil/meus-cursos"><li className={location.pathname == '/perfil/meus-cursos' ? 'selected' : ''}>Meus cursos</li></Link>
                            <Link to="/perfil/minhas-questoes"><li className={location.pathname == '/perfil/minhas-questoes' ? 'selected' : ''}>Minhas questões</li></Link>
                            <Link to="/perfil/meus-cadernos"><li className={location.pathname == '/perfil/meus-cadernos' ? 'selected' : ''}>Meus cadernos</li></Link>
                            <Link to="/perfil/meus-dados"><li className={location.pathname == '/perfil/meus-dados' ? 'selected' : ''}>Meus dados</li></Link>
                            <Link to="/perfil/trocar-senha"><li className={location.pathname == '/perfil/trocar-senha' ? 'selected' : ''}>Alterar senha</li></Link>
                            <Link to="/perfil/assinatura"><li className={location.pathname == '/perfil/assinatura' ? 'selected' : ''}>Assinatura</li></Link>
                            <Link to="#" onClick={logout}><li>Sair</li></Link>
                        </ul>
                    </div>
                </Col>
                <Col lg="9">
                    {props.children}
                </Col>
            </Row>
        </Container>
    </Layout>
  );
}