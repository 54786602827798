import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import Layout from '../../../layouts/Site';

import Header from '../../../components/Header';
import { NextCard } from '../../../components/Cards';

import api from '../../../services/api';

import Book from '../../../assets/img/header/book.svg';
import QuestionsImage from '../../../assets/img/presentations/questions.png';
import Logo from '../../../assets/img/logo.svg';

export default function View(props) {

  useEffect(() => {
  }, []);

  return (
    <div className="profile-card">
        <div className="wrapper">
            <div className="profileTitle">
                <h1>{props.title}</h1>
                <div>{props.extra}</div>
            </div>
            <div className="content">
                {props.children}
            </div>
        </div>
    </div>
  );
}