import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";
import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput, Table } from 'reactstrap';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import AdminLayout from '../../../../layouts/Admin';
import api from '../../../../services/api';

export default function View(props) {

    const [classes, setClasses] = useState([]);
    const [courseName, setCourseName] = useState("");

    useEffect(() => {
        api.get(`courses/${props.match.params.id}/classes`).then(res => {
            setClasses(res.data.classes);
        })
        api.get(`courses/${props.match.params.id}`).then(res => {
          setCourseName(res.data.course.name);
        })
    }, []);

    const [confirmationDialog, setConfirmationDialog] = useState(false);

    function toggleDialog(){
        setConfirmationDialog(!confirmationDialog);
    }

    function callbackDialog(){
        api.delete(`/classes/${confirmationDialog}`);
        setClasses(classes.filter(courseClass => courseClass.id != confirmationDialog));
        toggleDialog();
    }

    return (
        <AdminLayout
            title={`${courseName} - Aulas`}
            extra={
                <Link to="aulas/cadastrar" className="site-button site-button-sm">Cadastrar nova aula</Link>
            }
        >
            <Table responsive>
                <tbody>
                    {
                        classes.map((courseClass, index) => (
                            <tr>
                                <td className="text-center">{index + 1}</td>
                                <td>{courseClass.title}</td>
                                <td className="text-right">
                                    <Link to={`aulas/${courseClass.id}`} className="site-button site-button-sm">Editar</Link>{' '}
                                    <button className="site-button site-button-danger site-button-sm" onClick={() => setConfirmationDialog(courseClass.id)}>Deletar</button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

            <ConfirmationDialog
                isOpen={confirmationDialog}
                toggle={toggleDialog}
                callback={callbackDialog}
            >
                Você tem certeza que deseja deletar essa aula?
            </ConfirmationDialog>
        </AdminLayout>
    );
}