import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import Logo from '../../assets/img/logo.svg';

export default function Navbar(props) {

  useEffect(() => {
  }, []);

  return (
    <>
        <div className="header">
            {props.children}
        </div>
    </>
  );
}