import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import AdminLayout from '../../../../layouts/Admin';
import api from '../../../../services/api';

export default function View(props) {

  const [name, setName] = useState();
  const [vagas, setVagas] = useState();
  const [salary, setSalary] = useState();
  const [date, setDate] = useState();
  const [link, setLink] = useState();
  const [ toNexts, setToNexts ] = useState(false);

  useEffect(() => {
    api.get(`nexts/${props.match.params.id}`)
    .then(res => { 
      const { next } = res.data;

      setName(next.name);
      setVagas(next.vagas);
      setSalary(next.salary);
      setDate(next.date);
      setLink(next.link);
    })
  }, []);

  function submit(e){
    e.preventDefault();

    api.put(`nexts/${props.match.params.id}`, {
      name,
      vagas,
      salary,
      date,
      link
    }).then(res => {
      setToNexts('/admin/proximos-concursos');
    })
  }

  return (
    <Form onSubmit={submit}>
      { toNexts && <Redirect to={toNexts}/> }
      <AdminLayout
          title="Cadastrar próximo concurso"
          extra={
              <button className="site-button site-button-sm">Salvar alterações</button>
          }
      >
            <FormGroup>
              <Label for="nome">Nome do concurso</Label>
              <Input type="text" id="nome" value={name} onChange={(e) => setName(e.target.value)} required/>
            </FormGroup>
            <FormGroup>
              <Label for="vagas">Quantidade de vagas</Label>
              <Input type="text" id="vagas" value={vagas} onChange={(e) => setVagas(e.target.value)} required/>
            </FormGroup>
            <FormGroup>
              <Label for="salario">Salário máximo</Label>
              <Input type="text" id="salario" value={salary} onChange={(e) => setSalary(e.target.value)} required/>
            </FormGroup>
            <FormGroup>
              <Label for="data">Data do concurso</Label>
              <Input type="date" id="data" value={date} onChange={(e) => setDate(e.target.value)} required/>
            </FormGroup>
            <FormGroup>
              <Label for="link">Link pro edital</Label>
              <Input type="text" id="link" value={link} onChange={(e) => setLink(e.target.value)} required/>
            </FormGroup>
      </AdminLayout>
    </Form>
  );
}