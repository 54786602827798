import React, { useEffect, useState } from "react";

import { Container} from 'reactstrap';

import Layout from '../../../layouts/Site';

import { PlanCard } from '../../../components/Cards';

import api from '../../../services/api';

import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

export default function View() {

    const token = useSelector(state => state.auth.token);
    const [user, setUser] = useState({});

    useEffect(() => {
        if(token)
            setUser(jwtDecode(token).user);
    }, []);

    function replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    return (
    <Layout>
        <Container>
            <section id="signature-plans" className="section">
                <div className="text-center">
                    {
                        user.type == 'premium' &&
                        <p className="text-danger">Você já é um assinante do site. Aguarde o fim da sua assinatura atual pra poder renová-la.</p>
                    }
                </div>
                <div className="d-lg-flex justify-content-center align-items-start">
                    <PlanCard
                        name={"Plano GRÁTIS"}
                        buttonText={"COMEÇAR"}
                        free
                    />
                    <PlanCard
                        name={"Plano MENSAL"}
                        buttonText={"ASSINAR"}
                        value={26.99}
                        description={'pagamento mensal'}
                        slug={'mensal'}
                        disabled={user.type == 'premium'}
                    />
                    <PlanCard
                        name={"Plano ANUAL"}
                        buttonText={"ASSINAR"}
                        value={24.06}
                        yearly
                        description={'ou R$150,00 a vista'}
                        slug={'anual'}
                        disabled={user.type == 'premium'}
                    />
                </div>
            </section>
        </Container>
    </Layout>
  );
}