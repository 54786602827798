import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';

import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';

export default function Layout(props) {

  useEffect(() => {
  }, []);

  return (
    <>
      <div className="site">
        <Navbar/>
          {props.children}
        <Footer/>
      </div>
    </>
  );
}