import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';
import { useForm } from "react-hook-form";
import AdminLayout from '../../../../layouts/Admin';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import api from '../../../../services/api';

export default function View(props) {

    const { register, handleSubmit } = useForm();
    const [ toCourses, setToCourses ] = useState(false);
    const [course, setCourse] = useState({});
    const [ckData, setCkData] = useState();
  
    useEffect(() => {
        api.get(`courses/${props.match.params.id}`).then(res => {
            setCourse(res.data.course);
            setCkData(res.data.course.text);
        });
    }, []);

    function submit(data){
        if(ckData){
            let formData = new FormData();
            formData.append('name', data.name);
            formData.append('value', data.value.replace(',', '.'));
            if(data.thumb[0])
                formData.append('thumb', data.thumb[0]);
            formData.append('videoLink', data.videoLink);
            formData.append('text', ckData);
            formData.append('_method', 'PUT');

            api.post(`courses/${props.match.params.id}`, formData ).then(res => {
                setToCourses(true);
            });
        }
    }


  return (
    <>
      { toCourses && <Redirect to="/admin/cursos"/> }
      <Form onSubmit={handleSubmit(submit)} enctype='multipart/form-data'>
        <AdminLayout
            title="Editar curso"
            extra={
                <button className="site-button site-button-sm">Salvar alterações</button>
            }
        >
              <FormGroup>
                <Label for="name">Nome do curso</Label>
                <Input type="text" id="name" name="name" defaultValue={course.name} innerRef={register({ required: true })} required/>
              </FormGroup>
              <FormGroup>
                <Label for="value">Valor</Label>
                <Input type="text" id="value" name="value" defaultValue={course.value} innerRef={register({ required: true })} required/>
              </FormGroup>
                <div className="d-flex my-4">
                    <img src={`http://api.persistir.codenative.com.br/${course.thumbPath}`} height="80px"/>
                    <FormGroup>
                        <Label for="thumb">Thumbnail selecione para trocar</Label>
                        <Input type="file" id="thumb" name="thumb" defaultValue={course.thumbPath} innerRef={register()}/>
                    </FormGroup>
                </div>
              <FormGroup>
                <Label for="videoLink">Link do vídeo</Label>
                <Input type="text" id="videoLink" name="videoLink" defaultValue={course.videoLink} innerRef={register({ required: true })} required/>
              </FormGroup>
              <FormGroup>
                <Label for="text">Texto</Label>
                <CKEditor
                  className="d-inline"
                  editor={ ClassicEditor }
                  data={ckData}
                  onChange={ ( event, editor ) => {
                      setCkData(editor.getData());
                  } }
                  config={{
                      ckfinder: {uploadUrl: "https://api.persistir.codenative.com.br/api/ckeditor/upload"},
                      removePlugins: [ 'ImageCaption', 'ImageToolbar', 'MathType', 'ChemType' ],
                      toolbar: [
                        'bold',
                        'underline', 
                        'italic',
                        'strikethrough', 
                        'fontColor', 
                        'fontBackgroundColor',
                        '|',
                        'mathpreview',
                        'subscript', 
                        'superscript',
                        '|',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'imageUpload',
                        'blockQuote',
                        'insertTable',
                        'undo',
                        'redo',
                        'MathType',
                        'ChemType'
                      ],
                  }}
                />
              </FormGroup>
        </AdminLayout>
      </Form>
    </>
  );
}