import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';
import { useForm } from "react-hook-form";
import AdminLayout from '../../../../layouts/Admin';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import api from '../../../../services/api';

export default function View() {

  const { register, handleSubmit } = useForm();
  const [ toClasses, setToClasses ] = useState(false);

  function submit(data){
    if(ckData){
      let formData = new FormData();
      formData.append('name', data.name);
      formData.append('value', data.value.replace(',', '.'));
      formData.append('thumb', data.thumb[0]);
      formData.append('videoLink', data.videoLink);
      formData.append('text', ckData);

      api.post(`courses`, formData ).then(res => {
        setToClasses(res.data.course.id);
      })
    }
  }

  const [ckData, setCkData] = useState();

  return (
    <>
      { toClasses && <Redirect to={`${toClasses}/aulas`}/> }
      <Form onSubmit={handleSubmit(submit)} enctype='multipart/form-data'>
        <AdminLayout
            title="Cadastrar curso"
            extra={
                <button className="site-button site-button-sm">Finalizar cadastro</button>
            }
        >
              <FormGroup>
                <Label for="name">Nome do curso</Label>
                <Input type="text" id="name" name="name" innerRef={register({ required: true })} required/>
              </FormGroup>
              <FormGroup>
                <Label for="value">Valor</Label>
                <Input type="text" id="value" name="value" innerRef={register({ required: true })} required/>
              </FormGroup>
              <FormGroup>
                <Label for="thumb">Thumbnail</Label>
                <Input type="file" id="thumb" name="thumb" innerRef={register({ required: true })} required/>
              </FormGroup>
              <FormGroup>
                <Label for="videoLink">Link do vídeo</Label>
                <Input type="text" id="videoLink" name="videoLink" innerRef={register({ required: true })} required/>
              </FormGroup>
              <FormGroup>
                <Label for="text">Texto</Label>
                <CKEditor
                  className="d-inline"
                  editor={ ClassicEditor }
                  data={ckData}
                  onChange={ ( event, editor ) => {
                      setCkData(editor.getData());
                  } }
                  config={{
                      ckfinder: {uploadUrl: "https://api.persistir.codenative.com.br/api/ckeditor/upload"},
                      removePlugins: [ 'ImageCaption', 'ImageToolbar', 'MathType', 'ChemType' ],
                      toolbar: [
                        'bold',
                        'underline', 
                        'italic',
                        'strikethrough', 
                        'fontColor', 
                        'fontBackgroundColor',
                        '|',
                        'mathpreview',
                        'subscript', 
                        'superscript',
                        '|',
                        'link',
                        'bulletedList',
                        'numberedList',
                        '|',
                        'imageUpload',
                        'blockQuote',
                        'insertTable',
                        'undo',
                        'redo',
                        'MathType',
                        'ChemType'
                      ],
                  }}
                />
              </FormGroup>
        </AdminLayout>
      </Form>
    </>
  );
}