import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';


export default function Card(props) {

  useEffect(() => {
  }, []);

  function replaceAll(string, search, replace) {
    return string.split(search).join(replace);
  }

  return (
    props.id ?
      (
        props.page ?
        <Link className="courseThumb coursePriceThumb" to={`/cursos/${props.id}/${replaceAll(props.name.toLowerCase(), ' ', '-' )}`}>
          <img src={props.image} style={{width: '100%'}} alt={props.name}/>
          <div style={{padding: 10, textAlign: 'center', fontWeight: 'semibold'}}>
            <h1 style={{fontSize: '1.2em', marginBottom: 5, marginTop: 10}}>{props.name}</h1>
            <span style={{fontSize: '0.8em'}}>Por apenas</span><br/><h1 style={{fontSize: '1.5em', color: '#2087F5', margin: 0}}>R${parseFloat(props.price).toFixed(2).replace('.', ',')}</h1>
          </div>
        </Link>
        :
        <Link to={`/perfil/cursos/${props.id}`} className="courseThumb">
            <img src={props.image} style={{width: '100%'}} alt={props.name}/>
        </Link>
      )
    :
      <div className="courseThumb">
          <img src={props.image} style={{width: '100%'}} alt={props.name}/>
      </div>
  );
}