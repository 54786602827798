import React, { useEffect, useState } from "react";
import {
  Link,
} from "react-router-dom";
import { Row, Col, Container, CustomInput } from 'reactstrap';
import Thumb from '../../components/Courses/Thumb';
import api from '../../services/api';
import Layout from '../Site';
import { useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';

export default function ClassLayout(props) {

    const token = useSelector(state => state.auth.token);

    const [watched, setWatched] = useState(false);

    useEffect(() => {
        setWatched(props.courseClass.watched);
    }, [props.courseClass]);

    function watch(){
        if(!watched){
            api.post(`watched`, {
                class_id: props.courseClass.id,
                course_id: props.courseClass.course_id,
                user_id: jwtDecode(token).sub
            }).then(res => {
                var aux = props.courseClass;
                aux.watched = res.data.watched;
                props.setCourseClass(aux);
                setWatched(res.data.watched);
            });
        }
        else{
            api.delete(`watched/${watched.id}`);
            var aux = props.courseClass;
            aux.watched = null;
            props.setCourseClass(aux);
            setWatched(false);
        }
    }

    return (
    <Layout>
        <Container>
            <Row className="profile">
                <Col lg="3">
                    <div className="profile-card">
                        <div className="wrapper">
                            <Thumb 
                                image={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + props.courseClass.thumbPath}
                            />

                            <h1 style={{fontSize: '1em', marginTop: '10px'}}>{props.courseClass.name}</h1>
                        </div>

                        <div style={{padding: '0 15px'}}>
                            <strong>Menu</strong>
                        </div>
                        <ul className="sidebarul">
                            <Link to={`/perfil/cursos/${props.courseId}`}><li>Aulas</li></Link>
                            <Link to='/perfil/meus-cursos'><li>Meus cursos</li></Link>
                        </ul>

                        <div style={{padding: '0 15px'}}>
                            <strong>Materiais da aula</strong>
                        </div>
                        <ul className="sidebarul">
                            {
                                props.files.map(file => (
                                    <a href={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + file.path} target="_blank" key={'files'+file.id}><li>{file.name}</li></a>
                                ))
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg="9">
                    <div className="videoWrapper">
                        <iframe width="560" height="315" src={props.courseClass.videoLink} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    
                    <div className="mt-3">
                        <CustomInput type="checkbox" id="exampleCustomCheckbox" label="Marcar aula como assistida" onChange={watch} checked={watched} />
                    </div>

                    <div className="nextClasses">
                        <h1>Próximas aulas</h1>
                        <Row>
                            {
                                props.nextTree.map(next => (
                                    <Col lg="4">
                                        <Thumb 
                                            image={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + next.thumbPath}
                                            courseId={props.courseClass.course_id}
                                            classId={next.id}
                                        />
                                    </Col>
                                ))
                            }
                        </Row>
                    </div>
                </Col>
            </Row>
        </Container>
    </Layout>
  );
}