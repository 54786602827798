import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput, Table } from 'reactstrap';

import ConfirmationDialog from '../../../components/ConfirmationDialog';

import AdminLayout from '../../../layouts/Admin';
import api from '../../../services/api';

export default function View() {

    const [nexts, setNexts] = useState([]);

    useEffect(() => {
        api.get('nexts').then(res => { setNexts(res.data.nexts) });
    }, []);

    const [confirmationDialog, setConfirmationDialog] = useState(false);

    function toggleDialog(){
        setConfirmationDialog(!confirmationDialog);
    }

    function callbackDialog(){
        api.delete(`nexts/${confirmationDialog}`);
        setNexts(nexts.filter(next => next.id != confirmationDialog));
        toggleDialog();
    }

    return (
        <AdminLayout
            title="Próximos concursos"
            extra={
                <Link to="proximos-concursos/cadastrar" className="site-button site-button-sm">Cadastrar novo concurso</Link>
            }
        >
            <Table responsive>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th style={{width: '130px'}}>Data</th>
                        <th style={{width: '130px'}}></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        nexts.map(next => (
                            <tr>
                                <td>{next.name}</td>
                                <td>{next.date}</td>
                                <td className="text-right">
                                    <Link to={`proximos-concursos/editar/${next.id}`} className="site-button site-button-sm">Editar</Link>{' '}
                                    <button className="site-button site-button-danger site-button-sm" onClick={() => setConfirmationDialog(next.id)}>Deletar</button>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </Table>

            <ConfirmationDialog
                isOpen={confirmationDialog}
                toggle={toggleDialog}
                callback={callbackDialog}
            >
                Você tem certeza que deseja deletar esse concurso?
            </ConfirmationDialog>
        </AdminLayout>
    );
}