import React, { useEffect, useState } from "react";
import { Container, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import Layout from '../../../layouts/Site';
import Question from '../../../components/Questions/Question';
import Filters from '../../../components/Questions/Filters';
import api from '../../../services/api';
import Loader from '../../../components/Loader';
import ClipLoader from "react-spinners/ClipLoader";

export default function Home(props) {
	const [questions, setQuestions] = useState([]);
	const [premium, setPremium] = useState(false);

	const [nextPage, setNextPage] = useState();

	const [loading, setLoading] = useState(true);
	const [loadingContent, setLoadingContent] = useState(false);

	const [filtered, setFiltered] = useState(false);

	useEffect(() => {
		api.get('questions').then(res => {
			setQuestions(res.data.questions.data);
			setNextPage(res.data.questions.current_page + 1);
			setLoading(false);
		});
		api.get('auth/user').then(res => {
			if(res.data.user.type == 'premium' || res.data.user.type == 'admin'){
				setPremium(true);
			} else {
				setPremium(false);
			}
		})
	}, []);

	return (
		<Layout>
			{
				loading ?
				<Loader loading={loading}/>
				:
				<>
				<section id="questoes">
					<Container className="py-3">
						<h1 style={{fontSize: '1.5em'}}>Questões de concurso</h1>
					</Container>
					<Filters setFiltered={setFiltered} filterFunction={setQuestions} questions={questions} nextPage={nextPage} loadingContent={loadingContent} setLoadingContent={setLoadingContent}/>
				</section>
				<Container>
					<section id="questions" className="section">
						{ questions.map((question, index) => <Question filtered={filtered} index={index + 1} key={question.id} question={question} premium={premium}/>) }

						<ClipLoader
							size={25}
							color={"#2087F5"}
							loading={loadingContent}
							className="mb-4"
						/>
					</section>
				</Container>
				</>
			}
		</Layout>
	);
}