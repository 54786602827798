import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";
import { Form, FormGroup, Input, Label, Row, Col, Container, CustomInput  } from 'reactstrap';
import CourseLayout from '../../../../layouts/Course';
import Wrapper from '../../../../components/Profile/Wrapper';
import Thumb from '../../../../components/Courses/Thumb';
import api from '../../../../services/api';
import { useSelector } from 'react-redux';

export default function View(props) {

  const token = useSelector(state => state.auth.token);

  const [classes, setClasses] = useState([]);
  const [course, setCourse] = useState({});

  useEffect(() => {
    api.get(`courses/${props.match.params.id}/classes`).then(res => {
      setClasses(res.data.classes);
    });
    api.get(`courses/${props.match.params.id}`).then(res => {
      setCourse(res.data.course);
    });
  }, []);

  return (
    <CourseLayout name={course.name} thumbPath={course.thumbPath}>
        <Wrapper 
          title="Aulas"
          courseId={props.match.params.id}
        >
            <Row>
              {
                classes.map(courseClass => (
                  <Col lg="4" key={'classes'+courseClass.id}>
                    <Thumb 
                      image={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + courseClass.thumbPath}
                      courseId={props.match.params.id}
                      classId={courseClass.id}
                      watched={courseClass.watched ? true : false}
                    />
                  </Col>
                ))
              }
            </Row>
        </Wrapper>
    </CourseLayout>
  );
}