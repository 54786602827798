import React, { useEffect } from 'react';
import Routes from './Routes';
import './assets/styles/styles.css';

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';
import { BrowserRouter as Router } from 'react-router-dom';

function App() {
	return (
			<Provider store={ store }>
				<PersistGate persistor={ persistor }>
					<Router>
						<Routes />
					</Router>
				</PersistGate>
			</Provider>
	);
}

export default App;
