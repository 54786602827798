import React, { useEffect, useState } from "react";

import { Collapse, CardBody, Card } from 'reactstrap';

export default function FilterItem(props){

    const [isOpen, setOpen] = useState(false);

    return (
        <div className="collapsable">
            <button className="collapse-button" onClick={() => setOpen(!isOpen)}>
                <span>{props.name}</span> 
                {
                    isOpen ?
                        <i class="fas fa-minus"></i>
                    :
                        <i class="fas fa-plus"></i>
                }
            </button>
            <Collapse isOpen={isOpen}>
                <Card>
                    <CardBody>
                        {props.children}
                    </CardBody>
                </Card>
            </Collapse>
        </div>
    )
}