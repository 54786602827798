import React, { useEffect, useState } from "react";
import {
  Link,
  Redirect
} from "react-router-dom";

import { Form, FormGroup, Input, Label, Row, Col, Container, FormFeedback, InputGroupAddon, InputGroupText, InputGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import Layout from '../../../layouts/Site';
import { Card } from '../../../components/Cards';
import CourseCard from '../../../components/Courses/Card';
import api from '../../../services/api';

import ApprovedSVG from '../../../assets/img/checkout/approved.svg';
import RefusedSVG from '../../../assets/img/checkout/refused.svg';

import ClipLoader from "react-spinners/ClipLoader";

export default function Checkout(props) {

    const [course, setCourse] = useState({});

    const [description, setDescription] = useState(props.match.params.name);
    const [transactionAmount, setTransactionAmount] = useState(0);
    const [installments, setInstallments] = useState(1);
    const [email, setEmail] = useState();
    const [paymentMethodId, setPaymentMethodId] = useState(null);
    const [cardNumber, setCardNumber] = useState("");
    const [invalidCard, setInvalidCard] = useState(false);

    const [finishing, setFinishing] = useState(false);
    const [status, setStatus] = useState();

    const [modal, setModal] = useState(false);
    
    function toggle(){
        if(status == 'approved'){
            setToCourses(true);
        }
        setModal(!modal);
    }

    const [toCourses, setToCourses] = useState(false);

    useEffect(() => {
        api.get(`courses/${props.match.params.id}`).then(res => {
            setCourse(res.data.course);
            setTransactionAmount(res.data.course.value);
        });
        window.Mercadopago.setPublishableKey("TEST-f04f7ce3-dce9-4232-9e33-2608d91f5303");
    }, []);

    function replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    useEffect(() => {
        guessPaymentMethod();
    }, [cardNumber]);

    //Pega o tipo de bandeira do cartão
    function guessPaymentMethod() {
        if (cardNumber.length >= 6) {
            let bin = cardNumber.substring(0,6);
            window.Mercadopago.getPaymentMethod({
                "bin": bin
            }, setPaymentMethod);
        }
    }
    
    //Define o método de pagamento
    function setPaymentMethod(status, response) {
        if (status == 200) {
            let paymentMethodId = response[0].id;
            setPaymentMethodId(paymentMethodId);
            setInvalidCard(false);
        } else {
            setInvalidCard(true);
        }
    }

    useEffect(() => {
        getInstallments();
    }, [paymentMethodId])
    
    //Define as opções de parcelamento
    function getInstallments(){
        if(paymentMethodId){
            window.Mercadopago.getInstallments({
                "payment_method_id": paymentMethodId,
                "amount": transactionAmount
                
            }, function (status, response) {
                if (status == 200) {
                    document.getElementById('installments').options.length = 0;
                    response[0].payer_costs.forEach( installment => {
                        let opt = document.createElement('option');
                        opt.text = installment.recommended_message;
                        opt.value = installment.installments;
                        document.getElementById('installments').appendChild(opt);
                    });
                }
            });
        }
    }    

    const [doSubmit, setDoSubmit] = useState(false);

    function doPay(e){
        e.preventDefault();
        setFinishing(true);
        if(!doSubmit){
            var $form = document.querySelector('#pay');
    
            window.Mercadopago.createToken($form, sdkResponseHandler);
    
            return false;
        }
    }
    
    function sdkResponseHandler(status, response) {
        if (status != 200 && status != 201) {
            setStatus('rejected');
            setFinishing(false);
        }else{
            setDoSubmit(true);
            submit(response.id);
        }
    }

    function submit(token){
        setFinishing(true);
        api.post('buy', {
            transactionAmount,
            description,
            installments,
            email,
            token,
            paymentMethodId,
            course_id: props.match.params.id,
        }).then(res => {
            setFinishing(false);
            setStatus(res.data.status);
            if(res.data.status == 'rejected'){
                setDoSubmit(false);
            }
            toggle();
        });
    }

    return (
        
    <Layout>
        { toCourses && <Redirect to='/perfil/meus-cursos'/> }
        { status == 'rejected' && <Redirect to='/compra-recusada'/> }
        <Container>
            <section id="checkout" className="section">
                <Row className="course-page justify-content-center">
                    <Col lg="3" className="sidebar-wrapper">
                        <div className="course-page-card sidebar">
                            <div className="wrapper">
                                <CourseCard 
                                    color="#14763a" 
                                    image={api.defaults.baseURL.replace('.com.br/api', '.com.br/') + course.thumbPath}
                                />
                                <h1 className="name">{capitalizeFirstLetter(replaceAll(props.match.params.name, '-', ' '))}</h1>

                                <div className="course-page-valor">
                                    <p>Valor</p>
                                    <h1>R${transactionAmount.toLocaleString('pt-br', {minimumFractionDigits: 2})}</h1>
                                </div>
                            </div>
                            
                        </div>
                    </Col>
                    <Col lg="5">
                        <Card
                            title="Finalizar compra" 
                            contentCard
                        >
                            <Form id="pay" name="pay" onSubmit={doPay}>
                                <Row>
                                    <Col lg="8">
                                        <FormGroup>
                                            <Label for="cardNumber">Número do cartão</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><i class="far fa-credit-card"></i></InputGroupText>
                                                    </InputGroupAddon>
                                                <Input placeholder="____ ____ ____ ____" id="cardNumber" data-checkout="cardNumber" value={cardNumber} onChange={(e) => setCardNumber(e.target.value) } invalid={invalidCard} required/>
                                                <FormFeedback>Cartão inválido</FormFeedback>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <Label for="">CVV</Label>
                                            <Input type="text" id="securityCode" data-checkout="securityCode" required/>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label for="cardExpirationMonth">Mês de vencimento</Label>
                                            <Input type="text" id="cardExpirationMonth" data-checkout="cardExpirationMonth" required/>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <Label for="cardExpirationYear">Ano de vencimento</Label>
                                            <Input type="text" id="cardExpirationYear" data-checkout="cardExpirationYear" required/>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
                                            <Label for="">Nome e sobrenome</Label>
                                            <Input type="text" id="cardholderName" data-checkout="cardholderName" required/>
                                        </FormGroup>
                                    </Col>
                                    <input type="hidden" data-checkout="docType" id="docType" value="CPF"/>
                                    <input type="hidden" name="payment_method_id" id="payment_method_id" value={paymentMethodId}/>
                                    <Col lg="12">
                                        <FormGroup>
                                            <Label for="">CPF</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><i class="far fa-address-card"></i></InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder="___.___.___-__" id="docNumber" data-checkout="docNumber" required/>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
                                            <Label for="">Email</Label>
                                            <InputGroup>
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText><i class="fas fa-envelope"></i></InputGroupText>
                                                </InputGroupAddon>
                                                <Input type="email" id="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="12">
                                        <FormGroup>
                                            <Label for="">Parcelas</Label>
                                            <Input type="select" name="installments" id="installments" onChange={(e) => setInstallments(e.target.value)} required>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>
                                    {
                                        finishing ? 
                                        <ClipLoader
                                            size={25}
                                            color={"#2087F5"}
                                            loading={props.loading}
                                            className="mb-4"
                                        />
                                        :
                                        <button type="submit" className="site-button text-center">Finalizar compra</button>
                                    }
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </section>
        </Container>

        <Modal isOpen={modal} toggle={toggle}>
            {
                {
                'rejected': 
                <>
                    <ModalBody>
                        <div className="text-center">
                            <img src={RefusedSVG} height="200px"/>
                            <h1><strong>Compra rejeitada!</strong></h1>
                            <p>Verifique os seus dados de pagamento e se seu cartão está em ordem.</p>
                            <button className="site-button site-button" onClick={toggle}>Verificar meus dados</button>
                        </div>   
                    </ModalBody>
                </>,
                'approved': 
                <>
                    <ModalBody>
                        <div className="text-center">
                            <img src={ApprovedSVG} height="200px"/>
                            <h1><strong>Compra aprovada!</strong></h1>
                            <p>Em alguns instantes o seu curso vai estar disponível pra você no seu perfil.</p>
                            <button className="site-button" onClick={() => setToCourses(true)}>Ver meus cursos</button>
                        </div>
                    </ModalBody>
                    </>,
                }[status]
            }
        </Modal>
    </Layout>
  );
}